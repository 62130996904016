.usertable_address {
  width: 60%;
  margin-left: 10px;

}

.userstable_row {
  margin-left: 20px;
}

.usertable {
  display: flex;
  flex-direction: row;
}

.usertable_style {
  width: 20%;

}

.border {
  border: 20px solid rgb(255, 255, 255) !important;

}

.profilecard_dropdown {
  z-index: 999;
  height: 28vh;
  width: 16%;
  position: fixed;
  right: 0;
  margin-top: 7%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  /* background-color: #EEEEEE; */
  background-color: white;
  padding-right: 55px;
}

ul li {
  list-style: none;
  padding: 16px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

button {
  background: transparent;
  padding: 10px 30px;
  border-color: white;
  font: bolder 20px roboto;
}

@media only screen and (min-width:200px) and (max-width: 810px) {
  .profilecard_dropdown {
    background-color: white;
    height: 40vh;
    /* width: calc(100%-8%); */
    right: 0;
    position: fixed;
    margin-top: 90px;
    width: 120px;


  }

  ul li {
    list-style: none;
    padding: 17px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .nav_bar1 {
    display: grid;
  }
}

@media only screen and (min-width:768px) and (max-width: 1180px) {
  .profilecard_dropdown {
    background-color: white;
    height: 35vh;
    width: 20%;
    right: 0;
    position: fixed;
    margin-top: 90px;
  }

  ul li {
    list-style: none;
    padding: 17px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}




.updateprofile {
  height: 420px;
  width: 40%;
  margin-left: 20%;
}

.update_input {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 40px;
  margin-top: 8%;
}

.update_button {
  text-align: center;
  padding: 20px;
}

/* .reset_input {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 40px;
  margin-top: 8%;
} */

@media only screen and (min-width:200px) and (max-width: 810px) {

  .updateprofile {
    height: 50%;
    width: 91%;
    margin-left: 5%;
    margin-right: 10%;
  }

  .update_input {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 40px;
    margin-top: 8%;
  }

  .update_button {
    text-align: center;
    padding: 20px;
  }
}

@media only screen and (min-width:820px) and (max-width: 1024px) {

  .updateprofile {
    height: 50%;
    width: 74%;
    margin-left: 12%;
    margin-top: 5px;
  }

}

.grid_text {

  margin-top: 90px;
  padding: 10px 50px 50px 50px;
}

.border {
  border: 20px solid rgb(255, 255, 255) !important;
}

.users_u_table .sub-Heading {
  font-size: 1.6rem;
  font-weight: bold;
}

.border-one th {
  border: 1px solid #fff !important;
  padding: 10px 10px;
}

.border-one {
  margin-top: 20px;
}

.row_hover tr:hover {
  background-color: #ddd;
}

.Table-header .table_title_clr {
  color: white !important;
}

.details_list {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


/* .table_heading_clr{
  background-color:#374151;
}  */
.table_heading_clr {
  background-color: #37474F;
}

.box_style {
  height: 440px;
  height: 420px;
  width: 380px;
  padding: 20px;
  margin: 10px auto;
  margin-top: 100px;
}

.forgot_box {
  height: 400px;
  width: 380px;
  padding: 50px 20px 20px 20px;
  margin: 50px auto;
  margin-top: 100px;
}

.input_style {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 40px;
  margin-top: 60px;
}

.input_styles {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 40px;
  margin-top: 25px;
}

.login_button {
  text-align: center;
  padding: 40px;
}

.btn {
  background-color: #FFCA28;
}

.forgot_text {
  display: flex;
  justify-content: right;
  margin-top: -7%;
}

.forgot_text:hover {
  color: #FB8C00;
}

.textdecoration {
  text-decoration: none;
}

.recovery_button {
  margin-left: 150px;
  width: 100px;
}

.text {
  margin-left: 70px;
  text-decoration: none;
}

.vendor_create {
  padding: 20px;
}

.vendor_details {
  margin-top: 30px;
  margin-left: 20px;
  padding: 10px;
}

.btn_sub {
  padding: 50px 10px 50px 20px;
}

.nav {
  /* background-color: #222327; */
  background-color: #0D47A1;
  /* background: linear-gradient(#e30c9f, blue); */
  /* background-color:rgb(31, 101, 128); */
  color: white;

  height: 100vh;
  /* width: 100%; */
  position: fixed;
  width: 270px;
  min-height: 100vh;
  z-index: 3;
  left: 0;
  top: 0;
  font-size: large;
  color: white;
  text-decoration: none;
  overflow-y: auto;
  display: block;
}

.MuiSvgIcon-root {
  display: none !important;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
  display: none;
}

.nav-menu.active {
  background: rgb(35, 34, 36);
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  display: none;
}

.card {
  position: fixed;
  /* background-color: #374151 !important; */
  overflow-x: auto;
  top: 0;
  z-index: 2;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;

  @media only screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    height: 40px;
    align-items: center;
  }
}

.nav_bar {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  padding: 20px 2px 20px 20px;
  overflow-y: auto;
}

.nav_menu {
  padding: 13px;
  color: white;
  text-decoration: none;
}

.nav_menu:hover {
  cursor: pointer;
  color: #FB8C00;
}

.dashboard {
  /* background-color:; */
  background-color: #ededed;
  min-height: 100vh;
  padding: 120px 20px 15px 300px;
  /* margin-top: 50px; */


}

@media only screen and (max-width: 1024) {
  .nav {

    padding: 50px 20px 15px 300px;
  }

}

.icon-invis-vis {
  margin-top: 5px;
}

.header-invis {
  margin-left: 100px;
}

th,
td {
  border: 1px solid black;
}

@media only screen and (min-width: 480) and (max-width: 640px) {
  .nav {

    width: 20px !important;
  }

}

@media only screen and (min-width: 200px) and (max-width: 660px) {
  .nav_bar1 {
    display: grid;
  }

  .dashboard {
    background-color: #ededed;
    min-height: 100vh;
    padding: 120px 10px 15px 5px;
    /* margin-top: 50px; */
    /* margin-right: 50px; */
  }


  .MuiSvgIcon-root {
    display: block !important;
  }

  .nav {
    display: none;
    margin-top: 86px;
  }

  .header-invis {
    margin-right: 80%;
  }

  .nav_menu {
    color: black;
  }

  .close-btn {
    display: block;
  }

  .icon-invis-vis {
    margin-left: 20px;
  }
}

.upload_img {
  width: 80px;
  height: 80px;
  border: 1px solid black;

}